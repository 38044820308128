<style lang="scss">
@import "./MyAccount";
</style>

<template>
  <div id="my_account">
    <Header>
      Mon compte
      <small class="fw-light ms-2">{{ userFullname }}</small>
    </Header>
    <div class="p-3 mini_container">
      <div class="row row-cols-1 row-cols-lg-2 g-0 g-lg-5">
        <div class="col perso_data">
          <h3>Données personnelles</h3>
          <form class="row g-3" @submit.prevent="submit">
            <div>
              <label for="address1" class="form-label">Adresse</label>
              <input
                id="address1"
                type="text"
                class="form-control"
                v-model="account.address1_line1"
              />
            </div>
            <div>
              <input
                id="address2"
                type="text"
                class="form-control"
                v-model="account.address1_line2"
              />
            </div>
            <div class="col-sm-3">
              <label for="zipcode" class="form-label">Code postal</label>
              <input
                id="zipcode"
                type="text"
                class="form-control text-center"
                disabled
                :value="city?.qua_name"
              />
            </div>
            <div class="col-sm-9">
              <label for="city" class="form-label">Ville</label>
              <Multiselect
                id="city"
                noOptionsText="Taper un nom de ville ou un code postal"
                noResultsText="Aucune ville trouvée"
                :options="searchCommunes"
                :searchable="true"
                :caret="false"
                :delay="300"
                :minChars="3"
                :object="true"
                :filterResults="false"
                v-model="city"
              />
            </div>
            <div>
              <label for="email" class="form-label">Email</label>
              <input
                id="email"
                type="text"
                class="form-control"
                disabled
                v-model="account.emailaddress1"
              />
            </div>
            <div class="col-sm-6">
              <label for="phone" class="form-label">Téléphone</label>
              <input
                id="phone"
                type="text"
                class="form-control"
                v-model="account.telephone1"
              />
            </div>
            <div class="col-sm-6">
              <label for="mobile" class="form-label">Mobile</label>
              <input
                id="mobile"
                type="text"
                class="form-control"
                v-model="account.mobilephone"
              />
            </div>
            <div class="text-center">
              <button class="btn btn-primary" type="submit">Valider</button>
            </div>
          </form>
        </div>
        <div class="col">
          <h3 class="mt-4 mt-lg-0">Accès</h3>
          <div class="text-center">
            <div class="fw-light">
              Connectez-vous à Microsoft pour modifier votre
            </div>
            <a href="https://myaccount.microsoft.com" target="_blank"
              >Mot de passe</a
            >
          </div>
          <h3 class="mt-4">Données sources</h3>
          <p class="fw-light text-center">
            Sélectionnez le périmètre des données que vous voulez consulter
          </p>
          <div class="list-group">
            <label
              class="list-group-item"
              v-for="mandant in mandants"
              :key="mandant.accountid"
            >
              <input
                class="form-check-input me-1"
                type="checkbox"
                v-model="selectedMandantIds"
                :value="mandant.accountid"
              />
              {{ mandant.name }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./MyAccount.ts" />
