<template>
  <canvas ref="chart" :style="{ height: '170px', margin: '0 auto' }" />
</template>

<script>
import {
  Chart as ChartJS,
  ArcElement,
  DoughnutController,
  Legend,
  Tooltip,
} from "chart.js";
import { BI_DESTIN } from "@/utils/crm/crm-constants";

ChartJS.register(DoughnutController, ArcElement, Legend, Tooltip);

function createChart($element, idcFull) {
  const allowedIdc = BI_DESTIN.map(b => b.id.toString());
  const idc = idcFull.filter(i => allowedIdc.indexOf(i.id) !== -1);
  idc.sort((a, b) => b.value - a.value);
  const data = {
    labels: idc.map(
      (record) =>
        BI_DESTIN.find((option) => option.id === parseInt(record.id)).label
    ),
    datasets: [
      {
        data: idc.map((record) => record.value),
        backgroundColor: idc.map((record, index) => {
          const hue = Math.round((340 / idc.length) * index);
          return `hsl(${hue}, 70%, 70%)`;
        }),
      },
    ],
  };
  return new ChartJS($element, {
    type: "doughnut",
    data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      borderColor: "rgb(41, 54, 118)",
      borderWidth: 4,
      //   spacing: 20,
      animation: {
        animateRotate: false,
      },
      plugins: {
        legend: {
          position: "right",
          labels: {
            color: "#fff",
            boxHeight: 15,
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              var label = context.label,
                currentValue = context.raw,
                total = context.chart._metasets[context.datasetIndex].total;

              var percentage = parseFloat(
                ((currentValue / total) * 100).toFixed(1)
              );

              return label + ": " + currentValue + " (" + percentage + "%)";
            },
          },
        },
      },
    },
  });
}

export default {
  props: ["idc"],
  data() {
    return {
      chart: null,
    };
  },
  methods: {
    refresh(idc) {
      if (!idc.length) return;
      if (this.chart) this.chart.destroy();
      this.chart = createChart(this.$refs.chart, idc);
    },
  },
  watch: {
    idc(idc) {
      this.refresh(idc);
    },
  },
  activated() {
    this.refresh(this.idc);
  }
};
</script>
