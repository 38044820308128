import { moneyFormat, nFormat, nFormatArray } from "./display";

export default {
    methods: {
        moneyFormat(num) {
            return moneyFormat(num);
        },
        nFormatArray(num) {
            return nFormatArray(num);
        },
        nFormat(num) {
            return nFormat(num);
        },
    },
};
