import Multiselect from "@vueform/multiselect";
import { mapGetters } from 'vuex';
import { getAccounts } from "@/utils/msal";
import { SELECT_MANDANTS } from "@/store/mutations";

function getCityFromAccount(account) {
    if (!account) return null;
    return {
        value: account.qua_codepostal,
        label: account.address1_city,
        qua_name: account.qua_codepostal_label,
    };
}

export default {
    components: { Multiselect },
    data() {
        return {
            city: null,
        };
    },
    computed: {
        ...mapGetters({
            accountG: 'common/account',
            mandants: "common/mandants",
        }),
        userFullname() {
            return getAccounts()[0].name;
        },
        account() {
            if (!this.accountG) return {};
            return this.accountG;
        },
        selectedMandantIds: {
            get() {
                return this.$store.state.common.selectedMandantIds;
            },
            set(mandantIds) {
                this.$store.commit("common/" + SELECT_MANDANTS, mandantIds);
            },
        },
    },
    methods: {
        searchCommunes(search) {
            if (!search || typeof search !== 'string') return Promise.resolve(this.city ? [this.city] : []);
            return this.$store.dispatch('common/searchCommunes', search).then(communes => {
                return communes.map(commune => {
                    commune.value = commune.qua_communeid;
                    commune.label = commune.qua_ville + ` (${commune.qua_departement})`;
                    return commune;
                });
            });
        },
        submit() {
            if (this.city) this.account.qua_codepostal = this.city.value;
            this.$store.dispatch('common/putAccount', this.account).then(() => {
                this.$notify({ type: 'success', text: 'Le compte a été mis à jour avec succès' });
            });
        },
    },
    // watch: {
    //     account(account) {
    //         this.city = getCityFromAccount(account);
    //         console.log(this.city);
    //     },
    // },
    mounted() {
        this.city = getCityFromAccount(this.accountG);
    }
}
