<template>
  <div @click="refreshChart">
    <canvas ref="chart" :style="{ height: '170px', margin: '0 auto' }" />
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  BarController,
  BarElement,
  LinearScale,
  Tooltip,
} from "chart.js";

const DV_CATS = [
  { id: "souscandidature", label: "Sous candidature" },
  { id: "eninstruction", label: "En instruction" },
  { id: "souspromesse", label: "Sous promesse" },
];
ChartJS.register(
  CategoryScale,
  BarController,
  BarElement,
  LinearScale,
  Tooltip
);
ChartJS.defaults.color = "#fff";
let chart = null;

function createChart($element, idc) {
  idc.sort((a, b) => DV_CATS.indexOf(a.id) - DV_CATS.indexOf(b.id));
  const data = {
    labels: idc.map(
      (record) => DV_CATS.find((option) => option.id === record.id).label
    ),
    datasets: [
      {
        data: idc.map((record) => record.value),
        backgroundColor: idc.map((record, index) => {
          const hue = Math.round((340 / idc.length) * index);
          return `hsl(${hue}, 70%, 70%)`;
        }),
      },
    ],
  };
  return new ChartJS($element, {
    type: "bar",
    data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      // backgroundColor: 'rgba(255, 255, 255, .6)',
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  });
}

export default {
  props: ["idc"],
  methods: {
    refreshChart() {
      if (!this.idc.length) return;
      if (chart) chart.destroy();
      chart = createChart(this.$refs.chart, this.idc);
    },
  },
  watch: {
    idc() {
      this.refreshChart();
    },
  },
  activated() {
    /* The chart needs to be refreshed after the canvas is reinserted into the DOM */
    this.refreshChart();
  },
};
</script>
