<style lang="scss">
@import "vue3-carousel/dist/carousel";
@import "./Accueil";
</style>

<template>
  <div id="accueil">
    <Header :backButton="false">Accueil</Header>
    <div class="row g-0">
      <div class="col-xxl-8">
        <div class="p-3 p-lg-4">
          <div
            class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-3 g-lg-4 fs-4"
          >
            <div class="col">
              <div
                :class="['flipper', { flipped: flipped.vente }]"
                @click="flipped.vente = !flipped.vente"
              >
                <div class="card flip_front">
                  <div class="card-body indicator">
                    <h5 class="card-title indicator_title">Ventes réalisées</h5>
                    <div class="indicator_body">
                      <div class="indicator_col">
                        <IndicatorBlock
                          :value="idc.mso_biannee"
                          :tween="true"
                          foot="réalisé"
                        />
                        <IndicatorBlock
                          :value="idc.mso_biobjectif"
                          foot="objectif"
                        />
                      </div>
                      <div class="indicator_col">
                        <div class="indicator_block">
                          <ProgressRing
                            :percent="idc.mso_bipourcent"
                            :size="110"
                            :thickness="10"
                            :tween="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card flip_back bg_primary">
                  <div class="card-body indicator">
                    <h5 class="card-title indicator_title">
                      Ventes complémentaires
                    </h5>
                    <div class="indicator_body">
                      <div class="indicator_col">
                        <IndicatorBlock :value="idc.mso_bicomplement" />
                      </div>
                      <div class="indicator_col">
                        <IndicatorBlock
                          :value="idc.mso_cacomplement"
                          :money="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card">
                <div class="card-body indicator">
                  <h5 class="card-title indicator_title">Chiffre d'affaires</h5>
                  <div class="indicator_body">
                    <div class="indicator_col">
                      <IndicatorBlock
                        :value="idc.mso_caannee"
                        :money="true"
                        :tween="true"
                        foot="réalisé"
                      />
                      <IndicatorBlock
                        :value="idc.mso_caobjectif"
                        :money="true"
                        foot="objectif"
                      />
                    </div>
                    <div class="indicator_col">
                      <div class="indicator_block">
                        <ProgressRing
                          :percent="idc.mso_capourcent"
                          :size="110"
                          :thickness="10"
                          :tween="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card">
                <div class="card-body indicator">
                  <h5 class="card-title indicator_title">Tendances</h5>
                  <div class="indicator_body">
                    <div class="indicator_col">
                      <div class="indicator_head">C.A.</div>
                      <div class="indicator_block">
                        <fa
                          icon="arrow-right"
                          class="text-info"
                          :style="tendanceArrowStyle.ca"
                        />
                        <div class="indicator_foot">
                          {{ (idc.mso_catendance || 0) > 0 ? "+" : "" }}
                          {{ idc.mso_catendance || 0 }}%
                        </div>
                      </div>
                    </div>
                    <div class="indicator_col">
                      <div class="indicator_head">Ventes</div>
                      <div class="indicator_block">
                        <fa
                          icon="arrow-right"
                          class="text-info"
                          :style="tendanceArrowStyle.bi"
                        />
                        <div class="indicator_foot">
                          {{ (idc.mso_bitendance || 0) > 0 ? "+" : "" }}
                          {{ idc.mso_bitendance || 0 }}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div
                :class="['flipper', { flipped: flipped.ventenc }]"
                @click="flipped.ventenc = !flipped.ventenc"
              >
                <div class="card flip_front">
                  <div class="card-body indicator">
                    <h5 class="card-title indicator_title">Ventes en cours</h5>
                    <div class="indicator_body">
                      <div class="indicator_col">
                        <IndicatorBlock
                          :value="idc.mso_dvanneeencours"
                          foot="cette année"
                        />
                        <IndicatorBlock
                          :value="idc.mso_dvprecencours"
                          foot="report année(s) précédente(s)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card flip_back bg_primary_darker">
                  <div class="card-body indicator">
                    <h5 class="card-title indicator_title">Détail</h5>
                    <div class="indicator_body">
                      <div class="indicator_col">
                        <VenteChart :idc="venteIdc" @click.stop class="vente_chart" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <Carousel :wrap-around="true" :auto-play="false">
                <Slide key="stock">
                  <div class="card">
                    <div class="card-body indicator">
                      <h5 class="card-title indicator_title">
                        Stock de logements
                      </h5>
                      <div class="indicator_body">
                        <div class="indicator_col">
                          <IndicatorBlock
                            :value="idc.mso_bi"
                            :long="true"
                            foot="biens immobiliers"
                            class="big"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Slide>
                <Slide key="detail">
                  <div class="card bg_primary">
                    <div class="card-body indicator">
                      <h5 class="card-title indicator_title">Détail</h5>
                      <div class="indicator_body">
                        <div class="indicator_col">
                          <div class="indicator_head">Initial</div>
                          <IndicatorBlock
                            :value="idc.mso_biprecoccupe"
                            foot="occupés"
                          />
                          <IndicatorBlock
                            :value="idc.mso_biprecvacant"
                            foot="vacants"
                          />
                        </div>
                        <div class="indicator_col">
                          <div class="indicator_head">Réassort</div>
                          <IndicatorBlock
                            :value="idc.mso_bianneeoccupe"
                            foot="occupés"
                          />
                          <IndicatorBlock
                            :value="idc.mso_bianneevacant"
                            foot="vacants"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Slide>
                <Slide key="destin">
                  <div class="card bg_primary_darker">
                    <div class="card-body indicator">
                      <h5 class="card-title indicator_title">
                        Biens par destination
                      </h5>
                      <div class="indicator_body">
                        <div class="indicator_col">
                          <StockChart :idc="stockIdc" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Slide>
                <template #addons>
                  <Navigation />
                  <Pagination />
                </template>
              </Carousel>
            </div>

            <div class="col">
              <div class="card">
                <div class="card-body indicator">
                  <h5 class="card-title indicator_title">Désistements</h5>
                  <div class="indicator_body">
                    <div class="indicator_col">
                      <IndicatorBlock :value="idc.mso_dvanneeannules" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-4"></div>
    </div>
  </div>
</template>

<script src="./Accueil.ts" />
