import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';
import { mapGetters } from 'vuex';
import numberFormat from '@/utils/number-mixin';
import { cap } from '@/utils/display';
import ProgressRing from "@/views/common/ProgressRing.vue";
import IndicatorBlock from "@/views/common/IndicatorBlock.vue";
import StockChart from "./parts/StockChart.vue";
import VenteChart from "./parts/VenteChart.vue";

const indicatorKeys = [
    'mso_caannee',
    'mso_caobjectif',
    'mso_capourcent',
    'mso_catendance',
    'mso_biannee',
    'mso_biobjectif',
    'mso_bipourcent',
    'mso_bitendance',
    'mso_biprecoccupe',
    'mso_biprecvacant',
    'mso_bianneeoccupe',
    'mso_bianneevacant',
    'mso_cacomplement',
    'mso_bicomplement',
    'mso_dvanneeencours',
    'mso_dvprecencours',
    'qua_dvsouscandidature',
    'qua_dveninstruction',
    'qua_dvsouspromesse',
    'mso_bi',
    'qua_bi_975250004',
    'qua_bi_975250003',
    'qua_bi_975250000',
    'qua_bi_975250005',
    'qua_bi_975250001',
    'qua_bi_975250002',
    'mso_dvanneeannules',
];

function agregateIdc(indicators) {
    if (indicators.length === 1) return indicators[0];
    return indicators.reduce((a, b) => {
        indicatorKeys.forEach(key => {
            a[key] = (a?.[key] || 0) + (b?.[key] || 0);
        });
        return a;
    }, {});
}

function setPourcents(idc) {
    if (idc.mso_caannee && idc.mso_caobjectif) {
        idc.mso_capourcent = (idc.mso_caannee / idc.mso_caobjectif) * 100;
    }
    if (idc.mso_biannee && idc.mso_biobjectif) {
        idc.mso_bipourcent = (idc.mso_biannee / idc.mso_biobjectif) * 100;
    }
}

function dayOfTheYear(date) {
    return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
}

function setTendances(idc) {
    const currentDayOfYear = dayOfTheYear(new Date());
    if (idc.mso_caannee && idc.mso_caobjectif) {
        idc.mso_catendance = Math.round(((idc.mso_caannee / idc.mso_caobjectif) / (currentDayOfYear / 365) - 1) * 100);
    }
    if (idc.mso_biannee && idc.mso_biobjectif) {
        idc.mso_bitendance = Math.round(((idc.mso_biannee / idc.mso_biobjectif) / (currentDayOfYear / 365) - 1) * 100);
    }
}

function getTendanceArrowStyle(entity, idc) {
    const prop = `mso_${entity}tendance`;
    if (!idc[prop]) return {};
    const rotation = cap(idc[prop], { min: -100, max: 100 }) / 100 * -90;
    return {
        transform: `rotate(${rotation}deg)`,
    };
}

export default {
    components: {
        ProgressRing,
        IndicatorBlock,
        Carousel,
        Slide,
        Navigation,
        Pagination,
        StockChart,
        VenteChart,
    },
    mixins: [numberFormat],
    data() {
        return {
            flipped: {
                vente: false,
                ventenc: false,
            },
        };
    },
    computed: {
        ...mapGetters({
            indicators: 'common/indicators',
            selectedMandantIds: 'common/selectedMandantIds',
        }),
        idc() {
            if (!this.indicators.length) return {};
            const indicators = this.indicators.filter(idc => this.selectedMandantIds.includes(idc.accountid));
            const idc = agregateIdc(indicators);
            setPourcents(idc);
            setTendances(idc);
            // idc.qua_dvsouscandidature = 30000;
            // idc.qua_dveninstruction = 20000;
            // idc.qua_dvsouspromesse = 10000;
            return idc;
        },
        stockIdc() {
            return Object.keys(this.idc)
                .filter(key => key.startsWith('qua_bi_'))
                .map(key => ({
                    id: key.split('qua_bi_').slice(1)[0],
                    value: this.idc[key],
                }));
        },
        venteIdc() {
            return Object.keys(this.idc)
                .filter(key => [
                    'qua_dvsouscandidature',
                    'qua_dveninstruction',
                    'qua_dvsouspromesse',
                ].includes(key))
                .map(key => ({
                    id: key.split('qua_dv').slice(1)[0],
                    value: this.idc[key],
                }));
        },
        tendanceArrowStyle() {
            return {
                ca: getTendanceArrowStyle('ca', this.idc),
                bi: getTendanceArrowStyle('bi', this.idc),
            }
        },
    },
    mounted() {
        this.$store.dispatch('common/getIndicators');
    },
};
